<script lang="ts" setup>
import { computed, defineAsyncComponent, ref, watchEffect, onBeforeUnmount } from 'vue'
import { useContentStore } from '@/store/content'
import { getServerState, useRoute, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      market: getServerState('market'),
      isElskling: getServerState('isElskling'),
      route: getServerState('route'),
    }

const props = defineProps({
  minimal: {
    type: Boolean,
    default: false,
  },
})

const ZgIcon = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgIcon))

const emit = defineEmits(['track-navigation-click'])

const menuContent = useContentStore().getContent
const title = computed(() => menuContent?.phone?.title)

const isInsurance = ref<boolean>(!!ctx?.route?.isInsurance || !!useRoute()?.searchParams?.get('isInsurance'))
const phoneNumberDefault = computed(() => props.minimal
  ? menuContent?.elements?.header?.labels?.callUs ?? menuContent?.phone?.phoneNumber
  : menuContent?.phone?.phoneNumber)
const phoneNumberInsurance = ref<string | undefined>(menuContent?.phone?.phoneNumberInsurance)
const phoneNumber = ref<string | undefined>(phoneNumberDefault.value)

const triggerPhoneCall = () => {
  const phoneNo = menuContent?.phone?.phoneNumber
  if (!phoneNo) return

  emit('track-navigation-click', 'phone')
  window.location.href = `tel:${phoneNo}`
}

if (isInsurance.value) {
  phoneNumber.value = phoneNumberInsurance.value
} else {
  watchEffect(() => {
    if (ctx?.isElskling && !import.meta.env.SSR) {
      let observer: MutationObserver | null = null
      const ZgHeroTabClass = '.zg-hero-tab'

      const checkVisibleTab = () => {
        const tabs = document.querySelectorAll(ZgHeroTabClass)
        const visibleTab = Array.from(tabs).find(tab => {
          const tabElement = tab as HTMLElement
          return tabElement.checkVisibility() && tabElement.querySelector('#ins-forms_InsuranceLandingFragmentElskling')
        })

        phoneNumber.value = visibleTab ? phoneNumberInsurance.value : phoneNumberDefault.value
      }

      const observeDOM = () => {
        const targetNode = document.querySelector(ZgHeroTabClass)?.parentElement
        if (!targetNode) return

        observer = new MutationObserver(checkVisibleTab)
        observer.observe(targetNode, { childList: true, subtree: true, attributes: true })

        checkVisibleTab()
      }

      observeDOM()

      onBeforeUnmount(() => {
        if (observer) {
          observer.disconnect()
        }
      })
    }
  })
}
</script>

<template>
  <a
    v-if="phoneNumber"
    :aria-label="title"
    :href="`tel:${phoneNumber}`"
    :title="title"
    :class="['phone', {
      'phone--minimal': minimal,
      [`phone--${ctx?.market}`]: ctx?.market
    }]"
    @click="triggerPhoneCall"
  >
    <zg-icon icon="phone" size="xsmall" :aria-label="title" />

    <span class="phone-no">
      {{ phoneNumber }}
    </span>
  </a>
</template>

<style lang="scss" scoped>
  .phone {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(8px);
    height: rem(40px);
    cursor: pointer;
    border: none;

    .phone-no {
      @include type-title-xs;

      margin: 0;
    }

    &:hover {
      color: var(--brand-500);

      svg {
        color: var(--brand-500);
      }
    }
  }

  // ? ----------------------------------------------------------------------
  // modifiers
  // ? ----------------------------------------------------------------------

  .phone--se:not(.phone--minimal) {
    @include mq($until: 1430px) {
      .phone-no {
        display: none;
      }
    }
  }

  .phone--no:not(.phone--minimal) {
    @include mq($until: 1060px) {
      span {
        display: none;
      }
    }
  }

  .phone--fi:not(.phone--minimal) {
    @include mq($until: 1100px) {
      .phone-no {
        display: none;
      }
    }
  }

  .phone--minimal {
    @include mq($until: medium) {
      .phone-no {
        display: none;
      }
    }
  }
</style>
